import React, {useState, useEffect, useRef, useCallback} from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {createTheme, useTheme, ThemeProvider} from '@mui/material/styles';
import './Utils';
import {firstBy} from 'thenby';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import PictureInPictureAltIcon from '@mui/icons-material/PictureInPictureAlt';

const theme = createTheme({
  // palette: {
  //   primary: {
  //     main: `rgb(${Math.floor(50 + Math.random() * Math.floor(150))}, ${50 + Math.floor(Math.random() * Math.floor(150))}, ${50 + Math.floor(Math.random() * Math.floor(150))})`,
  //     main: 'rgb(245, 169, 32)',
  //     contrastText: 'rgba(255, 255, 255, 0.87)'
  //   },
  //   secondary: {
  //     main: 'rgb(231, 59, 45)',
  //     contrastText: 'rgba(255, 255, 255, 0.87)'
  //   }
  // },
  components: {
    MuiCssBaseline: {
      styleOverrides: (themeParam) => `
        html {
          boxSizing: 'border-box';
        }
      `,
    },

    MuiAutocomplete: {
      defaultProps: {
        fullWidth: true,
        size: 'small',
        variant: 'filled',
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingBottom: 8,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          minHeight: 56,
          paddingTop: 8,
          paddingLeft: 16,
          paddingRight: 16,
          paddingBottom: 16,
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        disableGutters: true,
        fixed: true,
      },
    },
    MuiDialogContentText: {
      defaultProps: {
        component: 'div',
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          marginTop: 8,
          marginBottom: 8,
        },
      },
    },
    MuiFab: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiList: {
      defaultProps: {
        disablePadding: true,
      },
    },
    MuiListItem: {
      defaultProps: {
        divider: true,
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          verticalAlign: 'bottom',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        size: 'small',
        variant: 'filled',
        // InputProps: {
        //   startAdornment: <InputAdornment position='start' />,
        // },
      },
    },
    MuiTooltip: {
      defaultProps: {
        disableInteractive: true,
        enterDelay: 250,
        placement: 'top',
      },
      styleOverrides: {
        tooltip: {
          marginLeft: '0px !important',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variant: 'body2',
      },
    },

  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          boxSizing: 'border-box'
        }
      }
    },
  }
});

const silentOps = [
  'getDummyThinghs',
];

export default function App(props) {
  const defaultFilter = {
    text: '',
    sizeMin: null,
    sizeMax: null,
    widthMin: null,
    widthMin: null,
    heightMin: null,
    heightMax: null,
    durationMin: null,
    durationMax: null,
  };
  const defaultSort = {
    by: 'id',
    order: 'asc',
  };
  const defaultOptions = {
    id: 'player',
    autoPlay: true,
    autopictureinpicture: 'true',
    controls: false,
    crossOrigin: 'anonymous',
    loop: false,
    muted: false,
    poster: null,
    preload: 'auto',
    width: '100%',
    height: '100%',
    style: {
      maxWidth: '100vw',
      maxHeight: 'calc(100vh - 353px)',
      // opacity: 10.025,
    },
  };
  const defaultVideo = {
    src: null,
    width: null,
    height: null,
    duration: null,
    currentTime: null,
    paused: null,
    playbackRate: 1,
    hasAudio: false,
    volume: 1,
    buffered: null,
  };
  const defaultPreview = {
    video: null,
    isVisible: false,
    left: 0,
    width: 160,
  };

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [files, setFiles] = useState([]);
  const [filter, setFilter] = useState(defaultFilter);
  const [sort, setSort] = useState(defaultSort);
  const [selectedFile, setSelectedFile] = useState(null);
  const [options, setOptions] = useState(defaultOptions);
  const [video, setVideo] = useState(defaultVideo);
  const [preview, setPreview] = useState(defaultPreview);
  const [uploads, setUploads] = useState([]);
  const [idIntervalCheckCurrentFile, setIdIntervalCheckCurrentFile] = useState(null);

  const handleVideoOnClickEvent = () => () => {
    if (document.querySelector('video#player').paused)
      document.querySelector('video#player').play();
    else
    document.querySelector('video#player').pause();
  };
  const handleVideoOnWheelEvent = useCallback(event => {
    event.preventDefault();
    document.querySelector('video#player').currentTime = document.querySelector('video#player').currentTime + ((event.wheelDeltaY > 0 ? 1 : -1) * (event.ctrlKey ? 60 : 3));
  }, [],);

  const refs = {
    video: useCallback(node => {
      if (node !== null)
        node.addEventListener('wheel', handleVideoOnWheelEvent, { passive: false });
    }, [handleVideoOnWheelEvent]),
  };

  useEffect(() => {
    const handleWindowKeyDownEvent = event => {
      let player = document.querySelector('#player');

      switch (event.code) {
        case 'Space':
          setVideo(prevState => ({
            ...prevState,
            paused: player.currentTime > 0 && !player.paused && !player.ended,
          }));
          break;
        case 'ArrowLeft':
          player.currentTime = player.currentTime - (event.ctrlKey ? 60 : 3);
          break;
        case 'ArrowRight':
          player.currentTime = player.currentTime + (event.ctrlKey ? 60 : 3);
          break;
        case 'ArrowUp':
          player.volume = Math.min(1, player.volume + .1);
          break;
        case 'ArrowDown':
          player.volume = Math.max(0, player.volume - .1);
          break;
        case 'NumpadAdd':
          document.querySelector('video#player').playbackRate = Math.min(document.querySelector('video#player').playbackRate * 2, 16);
          break;
        case 'NumpadSubtract':
          document.querySelector('video#player').playbackRate = Math.max(document.querySelector('video#player').playbackRate / 2, .25);
          break;
        case 'Escape':
          if (player.style.visibility === 'hidden') {
            player.style.visibility = 'visible';
          } else {
            setVideo(prevState => ({
              ...prevState,
              paused: true,
            }));
            player.style.visibility = 'hidden';
          }
          break;
        default:
      }
    };

    window.addEventListener('keydown', handleWindowKeyDownEvent);

    // handleGetUploads();

    return () => {
      window.removeEventListener('keydown', handleWindowKeyDownEvent);
    };
  }, []);

  useEffect(() => {
    const parseVideoMetadata = index => {
      var elVideo = document.createElement('video');
      elVideo.preload = 'metadata';

      elVideo.onloadedmetadata = async () => {
        window.URL.revokeObjectURL(elVideo.src);

        setFiles(prevState => Object.assign([], prevState, {[index]: {
          ...prevState[index],
          width: elVideo.videoWidth,
          height: elVideo.videoHeight,
          duration: Math.floor(elVideo.duration),
          isParsed: true,
        }}));
      };
      elVideo.onerror = async evt => {
        console.warn(`    elVideo.onerror for [${index + 1}/${files.length}] '${files[index].file.name}'`);
        window.URL.revokeObjectURL(elVideo.src);

        setFiles(prevState => Object.assign([], prevState, {[index]: {
          ...prevState[index],
          width: false,
          height: false,
          duration: false,
          isParsed: true,
        }}));
      };

      elVideo.src = URL.createObjectURL(files[index].file);
    };

    const index = files.findIndex(file => file.duration === null);
    if (index !== -1) {
      console.log(`Parsing metadata for video file [${index + 1}/${files.length}] '${files[index].file.name}'`);
      parseVideoMetadata(index);
    }

    // Update filter boundaries
    setFilter(prevState => ({
      ...prevState,
      sizeMin: getFilesSizeMarksMin(),
      sizeMax: getFilesSizeMarksMax(),
      widthMin: getFilesWidthMarksMin(),
      widthMax: getFilesWidthMarksMax(),
      heightMin: getFilesHeightMarksMin(),
      heightMax: getFilesHeightMarksMax(),
      durationMin: getFilesDurationMarksMin(),
      durationMax: getFilesDurationMarksMax(),
    }));
  }, [files]);

  useEffect(() => {
    if (document.querySelector('video#player') && document.querySelector('video#player').currentSrc !== video.src) {
      document.querySelector('video#player').load();
    }
  }, [video.src]);

  // useEffect(() => {
  //   console.log('selectedFile changed to', selectedFile);

  //   idIntervalCheckCurrentFile && clearInterval(idIntervalCheckCurrentFile);

  //   if (selectedFile !== null) {
  //     console.log('Setting up a file watcher');

  //     setIdIntervalCheckCurrentFile(setInterval(() => {
  //       fetch(URL.createObjectURL(files.find(file => file.id === selectedFile).file))
  //         .then(() => console.log('ok'))
  //         .catch(e => {
  //           console.log('File has been removed or renamed');
  //           const index = getFiles().findIndex(file => file.id === selectedFile);
  //           console.log('Removed file index is', index);
  //           const nextFile = getFiles()[index + 1] ? getFiles()[index + 1] : null;
  //           console.log('Next file is', nextFile);
  //           const nextIdFile = nextFile ? nextFile.id : null;
  //           console.log('Next idFile is', nextIdFile);
  //           handleFileSelect(nextIdFile)();
  //           setFiles(prevState => prevState.filter(file => file.id !== selectedFile));
  //           idIntervalCheckCurrentFile && clearInterval(idIntervalCheckCurrentFile);
  //           setIdIntervalCheckCurrentFile(null);
  //         });
  //     }, 1000));
  //   } else {
  //     setIdIntervalCheckCurrentFile(null);
  //   }
  // }, [selectedFile]);

  const ajax = async (op, data = {}, files = []) => {
    if (!Boolean(~silentOps.indexOf(op)))
      setIsLoading(true);

    const sid = user?.sid ? user.sid : localStorage.getObject(props.localStorageKey) ? localStorage.getObject(props.localStorageKey).sid : null;

    var formData = new FormData();
    formData.append('op', JSON.stringify(op));

    Object.keys(data).forEach(key => formData.append(key, JSON.stringify(data[key])));
    files.forEach((file, i) => formData.append(Boolean(file.paramName) ? file.paramName : `file_${i}`, Boolean(file.paramName) ? file.file : file));

    return await fetch(`${props.url.api}?op=${op}${sid ? `&sid=${sid}` : ''}`, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      redirect: 'follow',
      referrer: 'no-referrer',
      body: formData
    })
      .then(response => response.json())
      .then(response => {
        // console.log('App:', response);

        if (!Boolean(~silentOps.indexOf(op)))
          setIsLoading(false);

        if (!Boolean(response))
          throw new Error(`AJAX failed, response is not an object! The response is of type '${typeof response}' and its value is '${response}'`);

        if (Boolean(response.status.message))
          setMessage(response.status);

        if (response.result.userSession !== null)
          setUser(response.result.userSession);

        response.logs.forEach(log => console.log(`Ajax: ${log.type} - ${log.message}`));

        return response;
      })
      .then(response => {
        if (Boolean(response.result.attachments.length)) {
          function base64ToArrayBuffer(base64) {
            var binaryString =  window.atob(base64);
            var binaryLen = binaryString.length;
            var bytes = new Uint8Array(binaryLen);
            for (let i = 0; i < binaryLen; i++)        {
              var ascii = binaryString.charCodeAt(i);
              bytes[i] = ascii;
            }
            return bytes;
          }

          var saveByteArray = (function () {
            var a = document.createElement('a');
            document.body.appendChild(a);
            a.style = 'display: none';
            return function (data, contentType, name) {
              var blob = new Blob(data, {type: contentType});
              var url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = name;
              a.click();
              window.URL.revokeObjectURL(url);
              a.remove();
            };
          }());

          response.result.attachments.forEach(attachment => {
            saveByteArray([base64ToArrayBuffer(attachment.data)], attachment.contentType, attachment.fileName);
          });
        }

        return response;
      })
      .catch(err => {
        if (!Boolean(~silentOps.indexOf(op)))
          setIsLoading(false);
        setMessage({
          ok: false,
          message: 'Comunicarea cu serverul a esuat',
          severity: 'error'
        });
        console.error('Eroare: Comunicarea cu serverul a esuat cu urmatorul mesaj:\n', err);
      });
  };

  const handleGetUploads = () => {
    ajax('getUploads', {}).then(response => {
      console.log('Response:', response);
    });
  };

  const getHHMMSS = (duration, short = true) => {
    if (short) {
      return `${Math.floor(Math.floor(duration) / 3600) ? `${Math.floor(Math.floor(duration) / 3600)}:` : ''}${(Math.floor(Math.floor(duration) / 60) % 60).toString().padStart(Math.floor(Math.floor(duration) / 3600) ? 2 : 1, '0')}:${(Math.floor(duration) % 60).toString().padStart(2, '0')}`;
    } else {
      const hh = (Math.floor(duration / 3600)).toString().padStart(2, '0');
      const mm = (Math.floor(Math.floor(duration) / 60) % 60).toString().padStart(2, '0');
      const ss = (Math.floor(duration) % 60).toString().padStart(2, '0');
      return `${hh}:${mm}:${ss}`;
    }
  };
  const getFiles = () => files.filter(file => {
    return true
        && Boolean(~file.file.name.toLowerCase().indexOf(filter.text.toLowerCase()))
        && (file.file.size >= filter.sizeMin && file.file.size <= filter.sizeMax)
        && (file.width >= filter.widthMin && file.width <= filter.widthMax)
        && (file.height >= filter.heightMin && file.height <= filter.heightMax)
        && (file.duration >= filter.durationMin && file.duration <= filter.durationMax)
        && true;
  }).sort(firstBy(sort.by, {ignoreCase: true, direction: sort.order}));
  const getUnparsedVideos = () => files.filter(file => !file.isParsed);

  const getFilesSizeMarks = () => files.filter(file => file.file.size).map(file => ({value: file.file.size})).unique().sort(firstBy('value'));
  const getFilesSizeMarksMin = () => getFilesSizeMarks().min('value', getFilesSizeMarksMax());
  const getFilesSizeMarksMax = () => getFilesSizeMarks().max('value', 0);

  const getFilesWidthMarks = () => files.filter(file => file.width).map(file => ({value: file.width})).unique().sort(firstBy('value'));
  const getFilesWidthMarksMin = () => getFilesWidthMarks().min('value', getFilesWidthMarksMax());
  const getFilesWidthMarksMax = () => getFilesWidthMarks().max('value', 0);

  const getFilesHeightMarks = () => files.filter(file => file.height).map(file => ({value: file.height})).unique().sort(firstBy('value'));
  const getFilesHeightMarksMin = () => getFilesHeightMarks().min('value', getFilesHeightMarksMax());
  const getFilesHeightMarksMax = () => getFilesHeightMarks().max('value', 0);

  const getFilesDurationMarks = () => files.filter(file => file.duration).map(file => ({value: file.duration})).unique().sort(firstBy('value'));
  const getFilesDurationMarksMin = () => getFilesDurationMarks().min('value', getFilesDurationMarksMax());
  const getFilesDurationMarksMax = () => getFilesDurationMarks().max('value', 0);

  const handleFilesChange = () => event => {
    setSelectedFile(null);
    setFiles(Array.from(event.target.files).map((file, index) => ({
      file: file,
      id: index + 1,
      name: file.name,
      size: file.size,
      width: null,
      height: null,
      duration: null,
      isParsed: false,
    })));
    setFilter(defaultFilter);
    setOptions(defaultOptions);
    setVideo(defaultVideo);
    setPreview(defaultPreview);
  };
  const handleFilesClear = () => () => {
    setSelectedFile(null);
    setFiles([]);
    setOptions(defaultOptions);
    setVideo(defaultVideo);
    setPreview(defaultPreview);
  };
  const handleFileSelect = id => () => {
    if (id === selectedFile)
      return;

    setSelectedFile(id);
    setVideo(prevState => ({
      ...prevState,
      src: URL.createObjectURL(files.find(file => file.id === id).file),
    }));
  };

  const handleSortChange = sortBy => () => {
    setSort(prevState => ({
      by: sortBy,
      order: prevState.by !== sortBy ? 'asc' : prevState.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  const handleLoadVideo = src => () => {
    setVideo(prevState => ({
      ...prevState,
      src: src,
      currentTime: 0,
    }));
  };
  const handleVideoOnCanPlayEvent = () => event => {
    const index = files.findIndex(file => file.id === selectedFile);
    setVideo(prevState => ({
      ...prevState,
      hasAudio: event.target.mozHasAudio || Boolean(event.target.webkitAudioDecodedByteCount) || Boolean(event.target.audioTracks && event.target.audioTracks.length),
    }));
  };
  const handleUpdateVideo = event => {
    switch (event._reactName) {
    //   case 'onDurationChange': console.log(event._reactName, event.target.duration); break;
    //   case 'onLoadedData': console.log(event._reactName, `${event.target.videoWidth}x${event.target.videoHeight}, ${event.target.duration} seconds`); break;
      case 'onLoadedMetadata':
        // console.log(event._reactName, `${event.target.videoWidth}x${event.target.videoHeight}, ${event.target.duration} seconds`);
        setOptions({
          ...options,
          width: event.target.videoWidth,
          height: event.target.videoHeight,
        });

        document.querySelector('video#preview').setAttribute('src', video.src);
        document.querySelector('video#preview').load();
        break;
    //   case 'onPause': console.log(event._reactName, event.target.paused); break;
    //   case 'onPlay': console.log(event._reactName, event.target.paused); break;
    //   case 'onProgress': console.log(event._reactName, event.target.currentTime); break;
    //   case 'onRateChange': console.log(event._reactName, event.target.playbackRate); break;
    //   case 'onSeeked': console.log(event._reactName, event.target.currentTime); break;
    //   case 'onTimeUpdate': console.log(event._reactName, event.target.currentTime); break;
    //   case 'onVolumeChange': console.log(event._reactName, event.target.volume); break;
      default:
        // console.log('***', event._reactName, event);
        break;
    }
    setVideo(prevState => ({
      ...prevState,
      width: event.target.videoWidth,
      height: event.target.videoHeight,
      duration: event.target.duration,
      currentTime: event.target.currentTime,
      paused: event.target.paused,
      playbackRate: event.target.playbackRate,
      volume: event.target.volume,
      buffered: Boolean(event.target.buffered.length) ? event.target.buffered.end(event.target.buffered.length - 1) : video.buffered,
    }));
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', position: 'fixed', top: '0px', left: '0px', right: '0px', bottom: '0px',}}>
        {/**
         * Quick filter, select and clear files list
         */}
        <Grid container spacing={1}>
          <Grid item>
            <TextField variant='outlined' sx={{width: '300px'}}
              disabled={files.length === 0}
              placeholder='Quick filter...'
              value={filter.text}
              onChange={event => setFilter(prevState => ({
                ...prevState,
                text: event.target.value,
              }))}
            />
          </Grid>
          <Grid item sx={{flexGrow: 1}}>
            <Button component='label' variant='contained' size='large' color='secondary' fullWidth
              type='submit'
            >
              Select files...
              <input type='file' onChange={handleFilesChange()} hidden multiple />
            </Button>
          </Grid>
          <Grid item>
            <Button variant='contained' size='large' color='error' fullWidth
              disabled={files.length === 0}
              onClick={handleFilesClear()}
            >
              Clear files list
            </Button>
          </Grid>
        </Grid>

        {/**
        * Filter by size, width, height & duration
        */}
        {files.length > 0 &&
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3} sx={{flexGrow: 1, display: 'flex', alignItems: 'center'}}>
              <Typography variant='subtitle2'>Size</Typography>
              {getFilesSizeMarksMin() !== defaultFilter.sizeMin && getFilesSizeMarksMax() !== defaultFilter.sizeMax
                ? <Slider valueLabelDisplay='auto' sx={{mx: 2}}
                    min={getFilesSizeMarksMin()}
                    max={getFilesSizeMarksMax()}
                    step={null}
                    marks={getFilesSizeMarks()}
                    valueLabelFormat={value => `${(value / 1024 / 1024).toFixed(2)} MB`}
                    value={[filter.sizeMin, filter.sizeMax]}
                    onChange={(event, newValue) => setFilter(prevState => ({
                      ...prevState,
                      sizeMin: newValue[0],
                      sizeMax: newValue[1],
                    }))}
                  />
                : null
              }
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{flexGrow: 1, display: 'flex', alignItems: 'center'}}>
              <Typography variant='subtitle2'>Width</Typography>
              {getFilesWidthMarksMin() !== defaultFilter.widthMin && getFilesWidthMarksMax() !== defaultFilter.widthMax
                ? <Slider valueLabelDisplay='auto' sx={{mx: 2}}
                    min={getFilesWidthMarksMin()}
                    max={getFilesWidthMarksMax()}
                    step={null}
                    marks={getFilesWidthMarks()}
                    valueLabelFormat={value => `${value}px`}
                    value={[filter.widthMin, filter.widthMax]}
                    onChange={(event, newValue) => setFilter(prevState => ({
                      ...prevState,
                      widthMin: newValue[0],
                      widthMax: newValue[1],
                    }))}
                  />
                : null
              }
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{flexGrow: 1, display: 'flex', alignItems: 'center'}}>
              <Typography variant='subtitle2'>Height</Typography>
              {getFilesHeightMarksMin() !== defaultFilter.heightMin && getFilesHeightMarksMax() !== defaultFilter.heightMax
                ? <Slider valueLabelDisplay='auto' sx={{mx: 2}}
                    min={getFilesHeightMarksMin()}
                    max={getFilesHeightMarksMax()}
                    step={null}
                    marks={getFilesHeightMarks()}
                    valueLabelFormat={value => `${value}px`}
                    value={[filter.heightMin, filter.heightMax]}
                    onChange={(event, newValue) => setFilter(prevState => ({
                      ...prevState,
                      heightMin: newValue[0],
                      heightMax: newValue[1],
                    }))}
                  />
                : null
              }
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{flexGrow: 1, display: 'flex', alignItems: 'center'}}>
              <Typography variant='subtitle2'>Duration</Typography>
              {getFilesDurationMarksMin() !== defaultFilter.durationMin && getFilesDurationMarksMax() !== defaultFilter.durationMax
                ? <Slider valueLabelDisplay='auto' sx={{mx: 2}}
                    min={getFilesDurationMarksMin()}
                    max={getFilesDurationMarksMax()}
                    step={null}
                    marks={getFilesDurationMarks()}
                    valueLabelFormat={value => getHHMMSS(value)}
                    value={[filter.durationMin, filter.durationMax]}
                    onChange={(event, newValue) => setFilter(prevState => ({
                      ...prevState,
                      durationMin: newValue[0],
                      durationMax: newValue[1],
                    }))}
                  />
                : null
              }
            </Grid>
          </Grid>
        }

        {/**
        * Files browser list
        */}
        {files.length > 0
          ? <List dense sx={{minHeight: 230, maxHeight: 230, border: '1px solid lightgray', overflowX: 'hidden', overflowY: 'scroll'}}>
              <ListSubheader sx={{height: '32px', p: 0, lineHeight: '1rem'}}>
                <Grid container spacing={0} sx={{ml: 0}}>
                  <Grid item sx={{flexBasis: '40px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', p: 1, textAlign: 'right', color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.33)', cursor: 'pointer'}}
                    onClick={handleSortChange('id')}
                  >
                    <Typography variant='title2'>#</Typography>
                    {sort.by === 'id'
                      ? sort.order === 'asc'
                        ? <ArrowDropUpIcon fontSize='inherit' />
                        : <ArrowDropDownIcon fontSize='inherit' />
                      : null
                    }
                  </Grid>
                  <Grid item sx={{flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.33)', borderLeft: '1px solid white', cursor: 'pointer'}}
                    onClick={handleSortChange('name')}
                  >
                    <Typography variant='title2'>Name</Typography>
                    {sort.by === 'name'
                      ? sort.order === 'asc'
                        ? <ArrowDropUpIcon fontSize='inherit' />
                        : <ArrowDropDownIcon fontSize='inherit' />
                      : null
                    }
                  </Grid>
                  <Grid item sx={{flexBasis: '96px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', p: 1, textAlign: 'right', color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.33)', borderLeft: '1px solid white', cursor: 'pointer'}}
                    onClick={handleSortChange('size')}
                  >
                    <Typography variant='title2'>Size</Typography>
                    {sort.by === 'size'
                      ? sort.order === 'asc'
                        ? <ArrowDropUpIcon fontSize='inherit' />
                        : <ArrowDropDownIcon fontSize='inherit' />
                      : null
                    }
                  </Grid>
                  <Grid item sx={{flexBasis: '96px', p: 1, textAlign: 'right', color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.33)', borderLeft: '1px solid white'}}>Dimension</Grid>
                  <Grid item sx={{flexBasis: '96px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', p: 1, textAlign: 'right', color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.33)', borderLeft: '1px solid white', cursor: 'pointer'}}
                    onClick={handleSortChange('duration')}
                  >
                    <Typography variant='title2'>Duration</Typography>
                    {sort.by === 'duration'
                      ? sort.order === 'asc'
                        ? <ArrowDropUpIcon fontSize='inherit' />
                        : <ArrowDropDownIcon fontSize='inherit' />
                      : null
                    }
                  </Grid>
                </Grid>
                {getUnparsedVideos().length > 0
                  ? <LinearProgress variant='determinate' value={100 - 100 * getUnparsedVideos().length / files.length} />
                  : null
                }
              </ListSubheader>
              {getFiles().map(file => (
                <ListItemButton key={file.id} sx={{p: 0}}
                  selected={selectedFile === file.id}
                  onClick={handleFileSelect(file.id)}
                >
                  <ListItemText sx={{m: 0}}
                    primary={
                      <Grid container spacing={0} sx={{flexWrap: 'nowrap'}}>
                        <Grid item sx={{flexBasis: '40px', flexShrink: 0, flexGrow: 0, px: 1, py: .5, textAlign: 'right', borderLeft: '1px solid lightgray'}}>{file.id}</Grid>
                        <Grid item sx={{flexGrow: 1, px: 1, py: .5}}>{file.file.name}</Grid>
                        <Grid item sx={{flexBasis: '96px', flexShrink: 0, flexGrow: 0, px: 1, py: .5, textAlign: 'right', borderLeft: '1px solid lightgray'}}>{`${(file.file.size / 1024 / 1024).toFixed(2)} MB`}</Grid>
                        <Grid item sx={{flexBasis: '43px', flexShrink: 0, flexGrow: 0, pl: 1, py: .5, textAlign: 'right', borderLeft: '1px solid lightgray'}}>{file.isParsed ? file.width : '...'}</Grid>
                        <Grid item sx={{flexBasis: '10px', flexShrink: 0, flexGrow: 0, px: 0, py: .5, textAlign: 'center', color: 'rgba(0, 0, 0, 0.33)'}}>x</Grid>
                        <Grid item sx={{flexBasis: '43px', flexShrink: 0, flexGrow: 0, pr: 1, py: .5, textAlign: 'left'}}>{file.isParsed ? file.height : '...'}</Grid>
                        <Grid item sx={{flexBasis: '96px', flexShrink: 0, flexGrow: 0, px: 1, py: .5, textAlign: 'right', borderLeft: '1px solid lightgray'}}>{file.isParsed ? getHHMMSS(file.duration, false) : '...'}</Grid>
                      </Grid>
                    }
                  />
                </ListItemButton>
              ))}
            </List>
          : <Box>
              {uploads.map(upload => (
                <Box key={upload.id}>
                  <Box>Preview</Box>
                  <Box>Title</Box>
                  <Box>Member</Box>
                </Box>
              ))}
            </Box>
        }

        {/**
        * Video player
        */}
        {Boolean(video.src) &&
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '640px', maxWidth: '100vw', width: options.width, mx: 'auto', my: 1}}>
            <video ref={refs.video} {...options}
              onCanPlay={handleVideoOnCanPlayEvent()}
              onDurationChange={handleUpdateVideo}
              onLoadedData={handleUpdateVideo}
              onLoadedMetadata={handleUpdateVideo}
              onPause={handleUpdateVideo}
              onPlay={handleUpdateVideo}
              onProgress={handleUpdateVideo}
              onRateChange={handleUpdateVideo}
              onSeeked={handleUpdateVideo}
              onTimeUpdate={handleUpdateVideo}
              onVolumeChange={handleUpdateVideo}
              onClick={handleVideoOnClickEvent()}
              onDoubleClick={() => document.querySelector('video#player').requestFullscreen()}
            >
              <source src={video.src} />
            </video>

            {/**
             * Video player controls
             */}
            {Boolean(video.width) &&
              <Box sx={{display: 'flex', alignItems: 'center', minWidth: '640px', width: '100%', backgroundColor: 'rgba(0, 0, 0, .1)'}}>
                <Tooltip title={video.paused ? 'Play' : 'Pause'}>
                  <IconButton size='small' sx={{mr: .5}}
                    onClick={() => video.paused ? document.querySelector('video#player').play() : document.querySelector('video#player').pause()}
                  >
                      {video.paused
                        ? <PlayArrowIcon />
                        : <PauseIcon />
                      }
                  </IconButton>
                </Tooltip>

                <Typography sx={{mr: 1, whiteSpace: 'nowrap'}}>{getHHMMSS(video.currentTime)} / {getHHMMSS(video.duration)}</Typography>

                <Box sx={{flexBasis: '120px', flexShrink: 0, flexGrow: 1, position: 'relative', padding: '8px', backgroundColor: 'rgba(128, 128, 128, 0.7)', borderRadius: '16px'}}>
                  <LinearProgress variant='buffer'
                    value={video.duration ? video.currentTime / video.duration * 100 : 0}
                    valueBuffer={video.buffered ? video.buffered / video.duration * 100 : 0}
                  />
                  <Tooltip arrow followCursor title={getHHMMSS(document.querySelector('video#preview').currentTime)}>
                    <div style={{position: 'absolute', top: '0px', left: '8px', right: '8px', bottom: '0px', cursor: 'pointer'}}
                      onClick={event => document.querySelector('video#player').currentTime = video.duration * (event.clientX - event.target.getBoundingClientRect().left) / (event.target.clientWidth)}
                      onMouseEnter={() => setPreview({...preview, isVisible: true})}
                      onMouseMove={event => {
                        if (event.buttons === 1) {
                          document.querySelector('video#player').currentTime = video.duration * (event.clientX - event.target.getBoundingClientRect().left) / (event.target.clientWidth);
                        }
                        document.querySelector('video#preview').currentTime = video.duration * (event.clientX - event.target.getBoundingClientRect().left) / (event.target.clientWidth);
                        setPreview({
                          ...preview,
                          isVisible: true,
                          left: event.clientX - event.target.getBoundingClientRect().left - (preview.width / 2),
                        });
                      }}
                      onMouseLeave={() => setPreview({...preview, isVisible: false})}
                    >
                      <canvas
                        width={`${preview.width}px`}
                        height={video.height * preview.width / video.width}
                        style={{
                          display: preview.isVisible ? 'initial' : 'none',
                          boxSizing: 'content-box',
                          width: `${preview.width}px`,
                          height: `${video.height * preview.width / video.width}px`,
                          position: 'absolute',
                          top: `-${video.height * preview.width / video.width}px`,
                          left: `${preview.left}px`,
                          boxShadow: 'rgba(0, 0, 0, 0.8) 0 0 4px',
                        }}
                      />
                    </div>
                  </Tooltip>
                </Box>

                <Tooltip title={video.hasAudio ? `Volume ${Math.round(video.volume * 100)}%` : 'No audio'}>
                  <Box
                    sx={{
                      ml: .5,
                      '&:hover': {
                        cursor: 'pointer',
                        '.videoVolume': {
                          display: video.hasAudio ? 'block !important' : 'none',
                        },
                      },
                    }}
                  >
                    <IconButton size='small'
                      disabled={video.hasAudio === false}
                      onClick={event => {
                        document.querySelector('video#player').volume = video.volume === 0 ? 1 : 0
                      }}
                    >
                      {video.volume === 0
                        ? <VolumeOffIcon />
                        : <VolumeUpIcon />
                      }
                    </IconButton>
                    <div className='videoVolume' style={{display: 'none', position: 'absolute', height: '116px', marginTop: '-150px', padding: '8px', backgroundColor: 'rgba(0, 0, 0, 0.6)', borderRadius: '16px'}}>
                      <input type='range' min={0} max={100} step={1} orient='vertical' style={{/*appearance: 'slider-vertical',*/ writingMode: 'vertical-lr', direction: 'rtl', width: '8px', height: '100px'}}
                        value={Math.round(video.volume * 100)}
                        onChange={e => document.querySelector('video#player').volume = e.target.value / 100}
                      />
                    </div>
                  </Box>
                </Tooltip>

                <Tooltip title='Decrease playback speed'>
                  <span>
                    <IconButton size='small' sx={{ml: .5}}
                      disabled={video.playbackRate === .25}
                      onClick={() => document.querySelector('video#player').playbackRate = Math.max(document.querySelector('video#player').playbackRate / 2, .25)}
                    >
                      <FastRewindIcon />
                    </IconButton>
                  </span>
                </Tooltip>

                <Tooltip title='Actual playback speed'>
                  <Typography sx={{flex: '36px 0 0', textAlign: 'center'}}>{video.playbackRate}x</Typography>
                </Tooltip>

                <Tooltip title='Increase playback speed'>
                  <IconButton size='small' sx={{mr: .5}}
                    disabled={video.playbackRate === 16}
                    onClick={() => document.querySelector('video#player').playbackRate = Math.min(document.querySelector('video#player').playbackRate * 2, 16)}
                  >
                    <FastForwardIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title='Picture-in-picture'>
                  <IconButton size='small' sx={{mr: .5}}
                    onClick={() => document.pictureInPictureElement ? document.exitPictureInPicture() : document.querySelector('video#player').requestPictureInPicture()}
                  >
                    <PictureInPictureAltIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title='Full screen'>
                  <IconButton size='small'
                    onClick={() => document.querySelector('video#player').requestFullscreen()}
                  >
                    <FullscreenIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            }

            {/**
             * Video player preview thumbnail generator
             */}
            <video id='preview' muted style={{display: 'none', width: 0, height: 0, opacity: 0}}
              onSeeked={event => {
                // console.log('update video preview');
                if (document.querySelector('canvas')) {
                  document.querySelector('canvas').getContext('2d').drawImage(event.target, 0, 0, preview.width, video.height * preview.width / video.width);
                }
              }}
            >
              <source src='' />
            </video>
          </Box>
        }

        {/* <Box sx={{width: '100%'}}>
          <Typography>Thumbnails source: https://cdn5-thumbs.motherlessmedia.com/thumbs/E2E0B17-strip.jpg</Typography>
          <Typography>Src: {video.src}</Typography>
          <Typography>Width: {video.width}</Typography>
          <Typography>Height: {video.height}</Typography>
          <Typography>Duration: {getHHMMSS(video.duration)}</Typography>
          <Typography>Current time: {getHHMMSS(video.currentTime)}</Typography>
          <Typography>Paused: {video.paused ? 'YES' : 'NO'}</Typography>
          <Typography>Playback rate: {video.playbackRate}x</Typography>
          <Typography>Volume: {video.volume}/1</Typography>
          <Typography>Buffered: {video.buffered}</Typography>
        </Box> */}
        {/* <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
          {['E2E0B17', 'DC9C5A4', '8B15602', '2D7667D', '11FC39C', '95B61E4', 'CEBE467', '74903E6', '53B4F5C', '38269A6', '7BF0430', 'A641CFD', 'F241625', 'D7344A2', '2FA806D', '4FAAB96', 'B8EC726'].map(id => (
            <img key={id} alt={id} style={{width: 200, height: 160, margin: 1, cursor: 'pointer'}}
              src={`https://cdn5-thumbs.motherlessmedia.com/thumbs/${id}-small.jpg`}
              onClick={handleLoadVideo(`https://cdn5-videos.motherlessmedia.com/videos/${id}.mp4`)}
            />
          ))}
        </Box> */}
      </Box>
    </ThemeProvider>
  );
}