/**
 * var userObject = {userId: 24, name: 'Jack Bauer'};
 * localStorage.setObject(localStorageKey, userObject);
 */
Storage.prototype.setObject = function(key, value) {
  this.setItem(key, JSON.stringify(value));
};

/**
 * userObject = localStorage.getObject(localStorageKey);
 */
Storage.prototype.getObject = function(key) {
  var value = this.getItem(key);
  return value ? JSON.parse(value) : null;
};

const monthNames = {
   1: 'Ianuarie',
   2: 'Februarie',
   3: 'Martie',
   4: 'Aprilie',
   5: 'Mai',
   6: 'Iunie',
   7: 'Iulie',
   8: 'August',
   9: 'Septembrie',
  10: 'Octombrie',
  11: 'Noiembrie',
  12: 'Decembrie',
};
const monthShortNames = {
   1: 'Ian',
   2: 'Feb',
   3: 'Mar',
   4: 'Apr',
   5: 'Mai',
   6: 'Iun',
   7: 'Iul',
   8: 'Aug',
   9: 'Sep',
  10: 'Oct',
  11: 'Noi',
  12: 'Dec',
};
const weekdayNames = {
  0: 'Duminica',
  1: 'Luni',
  2: 'Marti',
  3: 'Miercuri',
  4: 'Joi',
  5: 'Vineri',
  6: 'Sambata',
};
const weekdayShortNames = {
  0: 'Dum',
  1: 'Lun',
  2: 'Mar',
  3: 'Mie',
  4: 'Joi',
  5: 'Vin',
  6: 'Sam',
};

// eslint-disable-next-line
Date.prototype.format = function(format = 'DD.MM.YYYY') {
  const year = this.getFullYear();
  const month = this.getMonth() + 1;
  const day = this.getDate();
  const weekday = this.getDay();
  const hours = this.getHours();
  const minutes = this.getMinutes();
  const seconds = this.getSeconds();

  var date = format;

  date = date.replace(/\bss\b/g, seconds.toString().padStart(2, '0'));
  date = date.replace(/\bs\b/g, seconds.toString());

  date = date.replace(/\bmm\b/g, minutes.toString().padStart(2, '0'));
  date = date.replace(/\bm\b/g, minutes.toString());

  date = date.replace(/\bhh\b/g, hours.toString().padStart(2, '0'));
  date = date.replace(/\bh\b/g, hours.toString());

  date = date.replace(/\bDDDD\b/g, weekdayNames[weekday.toString()]);
  date = date.replace(/\bDDD\b/g, weekdayShortNames[weekday.toString()]);
  date = date.replace(/\DD\b/g, day.toString().padStart(2, '0'));
  date = date.replace(/\bD\b/g, day.toString());

  date = date.replace(/\bMMMM\b/g, monthNames[month.toString()]);
  date = date.replace(/\bMMM\b/g, monthShortNames[month.toString()]);
  date = date.replace(/\bMM\b/g, month.toString().padStart(2, '0'));
  date = date.replace(/\bM\b/g, month.toString());

  date = date.replace(/\bYYYY\b/g, year.toString());

  return date;
};

// eslint-disable-next-line
Date.prototype.addDays = function(days = 0) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

Array.prototype.max = function(key, defaultValue = 0) {
  const maxValue = this.reduce((acc, item) => Math.max(acc, parseInt(item[key])), -Infinity);
  return Math.max(maxValue, defaultValue);
};
Array.prototype.min = function(key, defaultValue = 0) {
  const minValue = this.reduce((acc, item) => Math.min(acc, parseInt(item[key])), Infinity);
  return Math.min(minValue, defaultValue);
};
Array.prototype.unique = function() {
  return Array.from(new Set(this.map(JSON.stringify))).map(JSON.parse);
};